import {
  doc,
  getDoc,
  query,
  collection,
  getDocs,
  setDoc,
  getFirestore,
  deleteDoc,
} from "firebase/firestore";
import { db } from "./firebase.utils";
import { UserInfos } from "types/user.types";

import {
  getAuth,
  updateEmail,
  reauthenticateWithCredential,
  EmailAuthProvider,
} from "firebase/auth";

export const USERS_COLLECTION = "users";

export const updateEmailAuthAndUserDoc = async (
  newEmail: string,
  password: string, // Required to reauthenticate the user
  userId: string // Firestore userId (usually the uid of the authenticated user)
): Promise<void> => {
  const auth = getAuth();
  const firestore = getFirestore();

  const user = auth.currentUser;

  if (user) {
    try {
      // Step 1: Reauthenticate the user (necessary to update email)
      const credential = EmailAuthProvider.credential(user.email!, password);
      await reauthenticateWithCredential(user, credential);

      // Step 2: Update the user's email in Firebase Authentication
      await updateEmail(user, newEmail);

      // Step 3: Update the user's email in Firestore
      const userRef = doc(firestore, "users", userId);
      await setDoc(userRef, { email: newEmail }, { merge: true });

      console.log("Email updated successfully in both Auth and Firestore.");
    } catch (error) {
      console.error("Error updating email:", error);
      throw error;
    }
  } else {
    console.error("No authenticated user found.");
    throw new Error("User not authenticated.");
  }
};

export const updatingUserDoc = async (
  userId: string,
  additionalInformation = {}
) => {
  if (!userId) return;
  const userDocRef = doc(db, "users", userId);
  const userSnapshot = await getDoc(userDocRef);

  if (userSnapshot.exists()) {
    try {
      const userData = userSnapshot.data();
      const updatedUserData = { ...userData, ...additionalInformation };
      await setDoc(userDocRef, updatedUserData);
      return updatedUserData;
    } catch (error: any) {
      throw new Error("error when updating user" + error.message);
    }
  } else {
    throw new Error("User does not exist.");
  }
};

export class FirebaseUserGateway {
  async updateUser({ userId, data }: { userId: string; data: UserInfos }) {
    // await db.collection("users").doc(userId).update(data);
    const docRef = doc(db, USERS_COLLECTION, userId);
    const userDoc = await getDoc(docRef);
    await userDoc.data();
  }

  async getUserById(userId: string) {
    try {
      const docRef = doc(db, USERS_COLLECTION, userId);
      const userDoc = await getDoc(docRef);

      if (!userDoc.exists) {
        throw new Error(`User ${userId} does not exist in Firebase`);
      }
      const user = userDoc.data();
      return { ...user, id: userDoc.id };
    } catch (err) {
      console.log("error getting user", err);
    }
  }

  async getAllUsers(): Promise<UserInfos[]> {
    const collectionRef = collection(db, USERS_COLLECTION);
    const q = query(collectionRef);
    const querySnapshot = await getDocs(q);
    const documents = querySnapshot.docs.map((doc) => ({
      ...doc.data(),
      id: doc.id,
    })) as UserInfos[];
    return documents;
  }

  async linkAuthUserToUserDocument(userId: string, userCredentialUid: string) {
    try {
      // Get a reference to the user document in Firestore using the provided user ID
      const userDocRef = doc(db, "users", userId);

      // Check if the user document exists
      const userDocSnapshot = await getDoc(userDocRef);

      if (!userDocSnapshot.exists) {
        throw new Error(`User ${userId} does not exist in Firebase`);
      }

      // Create a new document with the authentication UID as the document ID
      const newUserDocRef = doc(db, "users", userCredentialUid);

      // Copy the data from the existing user document to the new document
      await setDoc(newUserDocRef, userDocSnapshot.data());

      // Delete the existing user document
      // await deleteDoc(userDocRef);

      console.log("User linked to the user document successfully.");
    } catch (error) {
      console.error("An error occurred:", error);
    }
  }
}

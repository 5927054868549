import { db } from "./firebase.utils";
import { doc, getDoc, query, collection, getDocs } from "firebase/firestore";
import { Bulletin } from "types/bulletin";

export const BULLETINS_COLLECTION = "bulletins";

export class FirebaseBulletinGateway {
  async getBulletins(): Promise<Bulletin[]> {
    const collectionRef = collection(db, BULLETINS_COLLECTION);
    const q = query(collectionRef);
    const querySnapshot = await getDocs(q);
    const documents = querySnapshot.docs.map((doc) => ({
      ...doc.data(),
      id: doc.id,
    })) as Bulletin[];
    return documents;
  }

  async getBulletinById(bulletinId: string): Promise<Bulletin | null> {
    try {
      const docRef = doc(db, BULLETINS_COLLECTION, bulletinId);
      const bulletinDoc = await getDoc(docRef);

      if (!bulletinDoc.exists) {
        throw new Error(`Bulletin ${bulletinId} does not exist in Firebase`);
      }

      const bulletin = bulletinDoc.data() as Bulletin;
      return bulletin;
    } catch (err) {
      console.error("Error getting bulletin", err);
      throw err; // Re-throw the error to propagate it
    }
  }
}

import React, { useEffect, useState } from "react";

import { ReunionForm } from "components/reunions_form/reunion_form";
import { HStack, VStack } from "components/ui/containers/stacks";
import { FileUpload } from "components/file_upload/components/file_upload";
import { PageWrapper } from "components/ui/containers/page";
import { H4 } from "components/ui/headings";

import { MembersToAdmin } from "routes/members_list/members_list_to_admin";
import Loader from "components/ui/loaders/loader";
import { ColorsSystem } from "components/ui/colors";
import styled from "styled-components";
import { UserInfos } from "types/user.types";
import { FirebaseUserGateway } from "utils/firebase/firebase.user";
import { TypeExtractForm } from "./members_extracts";
import { CreateMemberFromMemberWithNoAuth } from "./create_auth_to_existing_member";
import { useUserContext } from "contexts/user_context";

export const AdminSpace = () => {
  const [members, setMembers] = useState<UserInfos[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  const { currentUser } = useUserContext();
  const isUserCelyneAdmin = currentUser?.email === "celine.giaume@gmail.com";

  useEffect(() => {
    async function fetchMembers() {
      const usersGateway = new FirebaseUserGateway();
      const fetchedMembers = await usersGateway.getAllUsers();

      setMembers(fetchedMembers);
      setIsLoading(false);
    }
    fetchMembers();
  }, []);

  return (
    <PageWrapper>
      <H4>Espace admin du site</H4>

      {isLoading ? (
        <Loader />
      ) : (
        <VStack alignItems="center">
          <Wrapper>
            <Title>RÉUNIONS</Title>
            <HStack justifyContent="center" gap={30}>
              <ReunionForm />
              <FileUpload />
              {isUserCelyneAdmin && <CreateMemberFromMemberWithNoAuth />}
            </HStack>
          </Wrapper>

          <Wrapper>
            <Title>EXTRACTS</Title>
            <HStack gap={30}>
              <TypeExtractForm members={members} />
            </HStack>
          </Wrapper>

          <Wrapper>
            <Title>MEMBRES</Title>
            <MembersToAdmin members={members} />
          </Wrapper>
        </VStack>
      )}
    </PageWrapper>
  );
};

const Wrapper = styled(VStack)`
  border-top: 1px solid ${ColorsSystem.lightGrey};
  width: 100%;
  padding-top: 10px;
  margin-top: 20px;
  margin-bottom: 20px;
  align-items: center;
`;

const Title = styled(H4)`
  color: ${ColorsSystem.mediumGrey};
  margin-bottom: 40px;
`;

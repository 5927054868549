import { VStack } from "components/ui/containers/stacks";
import { BluePanelTitle, RegularText } from "components/ui/texts";
import { useContext } from "react";
import { ModalContext } from "components/ui/modal";
import {
  createAuthUserWithEmailAndPasswordByAdmin,
  createUserDocFromAuth,
} from "utils/firebase/firebase.utils";
import { FirebaseUserGateway } from "utils/firebase/firebase.user";
import { FilledButton } from "components/ui/buttons";
import { UserInfos } from "types/user.types";
import { H4 } from "components/ui/headings";
import { Panel } from "components/ui/cards";

const USER_ID = "NO_EMAIL_38e97f22-06be-4e0b-94e2-87c3683bbec4";
const PASSWORD = "password";
const EMAIL = "jeanyves.scotet@orange.fr";

export const CreateMemberFromMemberWithNoAuth = () => {
  const { handleModal } = useContext(ModalContext);

  const handleSubmit = async (
    userId: string,
    password: string,
    email: string
  ) => {
    const userGateway = new FirebaseUserGateway();
    const userWithNoAuth = (await userGateway.getUserById(userId)) as UserInfos;

    if (password.length < 6) {
      alert("Le mot de passe doit contenir au moins 6 caractères");
      return;
    }

    try {
      const { user } = await createAuthUserWithEmailAndPasswordByAdmin(
        email,
        password
      );

      console.log("got user", user);

      await createUserDocFromAuth(user, {
        lastName: userWithNoAuth.lastName,
        firstName: userWithNoAuth.firstName,
        login: userWithNoAuth.login,
        email,
        landLineNumber: userWithNoAuth.landLineNumber,
        phoneNumber: userWithNoAuth.phoneNumber,
        civilAdress: userWithNoAuth.civilAdress,
        region: userWithNoAuth.region,
        diploma: userWithNoAuth.diploma,
        birthdate: userWithNoAuth.birthdate,
        grade: userWithNoAuth.grade,
        DATECEN: userWithNoAuth.DATECEN,
        DATE1LCL: userWithNoAuth.DATE1LCL,
        DATE2CL: userWithNoAuth.DATE2CL,
        brigade: userWithNoAuth.brigade,
        army: userWithNoAuth.army,
        originArmy: userWithNoAuth.originArmy,
        maritalSituation: userWithNoAuth.maritalSituation,
        children: userWithNoAuth.children,
        permanentAdress: userWithNoAuth.permanentAdress,
        repReunion: userWithNoAuth.repReunion,
        promotion: userWithNoAuth.promotion,
        cotisations: userWithNoAuth.cotisations,
        isDeceased: userWithNoAuth.isDeceased,
        deceasedData: userWithNoAuth.deceasedData,
      });
      alert("Utilisateur créé avec succès!");
      handleModal();
    } catch (error: any) {
      if (error.code === "auth/email-already-in-use")
        alert("email déjà utilisé");
      console.log("error", error);
    }
  };
  return (
    <Panel>
      <VStack fullHeight justifyContent="space-between">
        <VStack gap={18}>
          <H4>Créer un membre avec un email existant</H4>
          <RegularText>avec l'id : {USER_ID}</RegularText>
          <RegularText>avec le mot de passe : {PASSWORD}</RegularText>
          <RegularText>et l'email : {EMAIL}</RegularText>
        </VStack>
        <FilledButton
          onClick={() => handleSubmit(USER_ID, PASSWORD, EMAIL)}
          buttonSize={"small"}
          title="new member from old member"
          disabled={true}
        />
      </VStack>
    </Panel>
  );
};

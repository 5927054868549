import { getCotisationFromLegacyCotisation } from "helpers/helpers";
import React from "react";
import { UserInfos } from "types/user.types";
import * as XLSX from "xlsx";

const generateExcelAndDownload = (
  data: Partial<UserInfos>[],
  fileName: string
) => {
  const dataArray = data.map((user) => {
    const userCivilAdress =
      user.civilAdress?.numberAdress + " " + user.civilAdress?.adress;
    const userCivilZipCode = user.civilAdress?.zipcode;
    const userCivilCity = user.civilAdress?.city;

    const userDataArray = [
      "",
      user.id,
      user.type,
      user.lastName,
      user.firstName,
      userCivilAdress,
      userCivilZipCode,
      userCivilCity,
      user.email,
    ];
    const userCotisationsUpdated = user.cotisations?.map((cotisation) => {
      return getCotisationFromLegacyCotisation(cotisation);
    });

    userCotisationsUpdated
      ?.slice(-10)
      .reverse()
      .forEach((cotisation) => {
        userDataArray.push(
          cotisation.date,
          cotisation.paymentType,
          cotisation.value.toString(),
          cotisation.value.toString()
        );
      });

    return userDataArray;
  });

  const workbook = XLSX.utils.book_new();

  const worksheet = XLSX.utils.aoa_to_sheet([
    [
      "tri",
      "ID",
      "Type",
      "Nom",
      "Prénom",
      "Adresse civile",
      "Code postal",
      "Ville",
      "Email",
      "Coti 1 date",
      "Coti 1 type paiement",
      "Coti 1 valeur",
      "Coti 1 valeur en lettre",
      "Coti 2 date",
      "Coti 2 type paiement",
      "Coti 2 valeur",
      "Coti 2 valeur en lettre",
      "Coti 3 date",
      "Coti 3 type paiement",
      "Coti 3 valeur",
      "Coti 3 valeur en lettre",
      "Coti 4 date",
      "Coti 4 type paiement",
      "Coti 4 valeur",
      "Coti 4 valeur en lettre",
      "Coti 5 date",
      "Coti 5 type paiement",
      "Coti 5 valeur",
      "Coti 5 valeur en lettre",
      "Coti 6 date",
      "Coti 6 type paiement",
      "Coti 6 valeur",
      "Coti 6 valeur en lettre",
      "Coti 7 date",
      "Coti 7 type paiement",
      "Coti 7 valeur",
      "Coti 7 valeur en lettre",
      "Coti 8 date",
      "Coti 8 type paiement",
      "Coti 8 valeur",
      "Coti 8 valeur en lettre",
      "Coti 9 date",
      "Coti 9 type paiement",
      "Coti 9 valeur",
      "Coti 9 valeur en lettre",
      "Coti 10 date",
      "Coti 10 type paiement",
      "Coti 10 valeur",
      "Coti 10 valeur en lettre",
    ],
    ...dataArray,
  ]);

  XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

  const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });

  const blob = new Blob([excelBuffer], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });

  const url = URL.createObjectURL(blob);

  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", fileName + ".xlsx");

  document.body.appendChild(link);

  link.click();

  URL.revokeObjectURL(url);
  document.body.removeChild(link);
};

export default generateExcelAndDownload;

import { initializeApp } from "firebase/app";
import {
  getAuth,
  signInWithRedirect,
  signInWithPopup,
  GoogleAuthProvider,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  onAuthStateChanged,
  NextOrObserver,
  User,
  sendPasswordResetEmail,
  setPersistence,
  browserSessionPersistence,
} from "firebase/auth";
import { getFirestore, doc, getDoc, setDoc } from "firebase/firestore";
import { getStorage, ref } from "firebase/storage";
import { Option } from "types/global_types";
import { UserInfos } from "types/user.types";

// const firebaseConfig = {
//   apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
//   authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
//   projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
//   storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
//   messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
//   appId: process.env.REACT_APP_FIREBASE_APP_ID,
// };

const firebaseConfig = {
  apiKey: "AIzaSyAU7L5v3l-Wu8vxFf10S9QFNw1YSPmIvbM",

  authDomain: "site-promo-laurier.firebaseapp.com",

  projectId: "site-promo-laurier",

  storageBucket: "site-promo-laurier.appspot.com",

  messagingSenderId: "944798732600",

  appId: "1:944798732600:web:184d29f96c814a601a31cd",
};

// Initialize Firebase

const firebaseApp = initializeApp(firebaseConfig);
const firebaseAppAdmin = initializeApp({ ...firebaseConfig }, "horrible");

const googleProvider = new GoogleAuthProvider();
googleProvider.setCustomParameters({ prompt: "select account" });

export const auth = getAuth(firebaseApp);

setPersistence(auth, browserSessionPersistence).catch((error) => {
  console.error("Erreur de persistance Firebase :", error);
});

export const authAdmin = getAuth(firebaseAppAdmin);
setPersistence(authAdmin, browserSessionPersistence).catch((error) => {
  console.error("Erreur de persistance Firebase :", error);
});
export const storage = getStorage(firebaseApp);
export const signInWithGooglePopup = () =>
  signInWithPopup(auth, googleProvider);
export const signInWithGoogleRedirect = () =>
  signInWithRedirect(auth, googleProvider);

export const db = getFirestore();
export const createUserDocFromAuth = async (
  userAuth: any,
  additionalInformation = {}
): Promise<Partial<Option<UserInfos>>> => {
  if (!userAuth) return null;
  const userDocRef = doc(db, "users", userAuth.uid);
  const userSnapshot = await getDoc(userDocRef);

  if (userSnapshot.exists()) return userSnapshot.data();

  const { email } = userAuth;
  const createdAt = new Date();
  try {
    const userData = {
      email,
      createdAt,
      ...additionalInformation,
      id: userAuth.uid,
    };
    await setDoc(userDocRef, userData);
    return userData;
  } catch (error) {
    throw new Error("error when creating user" + error);
  }
};

export const createAuthUserWithEmailAndPassword = async (
  email: string,
  password: string
) => {
  return await createUserWithEmailAndPassword(auth, email, password);
};
export const createAuthUserWithEmailAndPasswordByAdmin = async (
  email: string,
  password: string
) => {
  return await createUserWithEmailAndPassword(authAdmin, email, password);
};

export const signInAuthUserWithEmailAndPassword = async (
  email: string,
  password: string
) => {
  if (!email || !password) return;
  return await signInWithEmailAndPassword(auth, email, password);
};

export const signOutUser = async () => await signOut(auth);
export const signOutAdminUser = async () => await signOut(authAdmin);
export const signOutAll = async () => {
  await Promise.allSettled([signOutAdminUser(), signOutUser()]);
  window.location.reload();
};

export const onAuthStateChangedListener = (callback: NextOrObserver<User>) => {
  onAuthStateChanged(auth, callback);
};

export const resetPassword = async (email: string): Promise<void> => {
  try {
    await sendPasswordResetEmail(auth, email);
    alert("Un email de réinitialisation a été envoyé à votre adresse email.");
  } catch (error) {
    console.error(
      "Erreur lors de l'envoi de l'email de réinitialisation",
      error
    );
    alert("Une erreur est survenue. Veuillez réessayer.");
  }
};

import { Outlet, Link, useLocation } from "react-router-dom";
import { Header } from "../../components/ui/header";
import { useUserContext } from "../../contexts/user_context";
import { signOutAll } from "../../utils/firebase/firebase.utils";
import {
  IoBookOutline,
  IoBookSharp,
  IoDownloadSharp,
  IoPeopleSharp,
} from "react-icons/io5";
import { IoHome } from "react-icons/io5";
import { IoMail } from "react-icons/io5";
import { IoImages } from "react-icons/io5";
import { IoBuild } from "react-icons/io5";
import { IoPerson } from "react-icons/io5";
import { IoLogOut } from "react-icons/io5";
import { IoLogIn } from "react-icons/io5";
import { RegularText } from "components/ui/texts";
import styled, { css } from "styled-components";
import { HStack, VStack } from "components/ui/containers/stacks";
import { ColorsSystem } from "components/ui/colors";
import { useState } from "react";

export const NavBar = () => {
  const { currentUser } = useUserContext();
  const { pathname } = useLocation();

  const admins = [
    "celyne.giaume@ouilive.info",
    "celine.giaume@gmail.com",
    "celyne@giaume.com",
    "herve@giaume.com",
  ];
  const isCurrentUserAdmin = admins.includes(currentUser?.email || "");

  const getActivePath = () => {
    if (pathname.includes("reunionspromo")) {
      return "reunionspromo";
    } else if (pathname.includes("membres")) {
      return "membres";
    } else if (pathname.includes("presentation_promo")) {
      return "presentation_promo";
    } else if (pathname.includes("contacts")) {
      return "contacts";
    } else if (pathname.includes("admin")) {
      return "admin";
    } else if (pathname.includes("profile")) {
      return "profile";
    } else if (pathname.includes("bulletin")) {
      return "bulletin";
    } else {
      return "accueil";
    }
  };

  const [activePath, setActivePath] = useState<string>(getActivePath());

  return (
    <>
      <Header />
      <NavWrapper>
        <NavSection>
          <Link to={"/"} onClick={() => setActivePath("accueil")}>
            <LinkContainer>
              <HomeIcon isSelected={activePath === "accueil"} size={26} />
              <LinkText isSelected={activePath === "accueil"}>Accueil</LinkText>
            </LinkContainer>
          </Link>

          <Link
            to={"/presentation_promo"}
            onClick={() => setActivePath("presentation_promo")}
          >
            <LinkContainer>
              <PresentationIcon
                isSelected={activePath === "presentation_promo"}
                size={26}
              />
              <LinkText isSelected={activePath === "presentation_promo"}>
                Présentation
              </LinkText>
            </LinkContainer>
          </Link>
          <Link
            to={"/reunionspromo"}
            onClick={() => setActivePath("reunionspromo")}
          >
            <LinkContainer>
              <PhotoGalleryIcon
                isSelected={activePath === "reunionspromo"}
                size={26}
              />
              <LinkText isSelected={activePath === "reunionspromo"}>
                Galerie Photos
              </LinkText>
            </LinkContainer>
          </Link>

          <Link to={"/membres"} onClick={() => setActivePath("members")}>
            <LinkContainer>
              <MembersIcon isSelected={activePath === "members"} size={26} />
              <LinkText isSelected={activePath === "members"}>Membres</LinkText>
            </LinkContainer>
          </Link>

          <Link to={"/contacts"} onClick={() => setActivePath("contacts")}>
            <LinkContainer>
              <ContactIcon isSelected={activePath === "contacts"} size={26} />
              <LinkText isSelected={activePath === "contacts"}>
                Contacts
              </LinkText>
            </LinkContainer>
          </Link>

          <Link to={"/bulletin"} onClick={() => setActivePath("bulletin")}>
            <LinkContainer>
              <BulletinIcon isSelected={activePath === "bulletin"} size={26} />
              <LinkText isSelected={activePath === "bulletin"}>
                Bulletin
              </LinkText>
            </LinkContainer>
          </Link>

          {isCurrentUserAdmin && (
            <Link to={"/admin"} onClick={() => setActivePath("admin")}>
              <LinkContainer>
                <AdminIcon isSelected={activePath === "admin"} size={26} />
                <LinkText isSelected={activePath === "admin"}>Admin</LinkText>
              </LinkContainer>
            </Link>
          )}
        </NavSection>
        <NavSection>
          <Link to={"/profile"} onClick={() => setActivePath("profile")}>
            <LinkContainer>
              <UserIcon isSelected={activePath === "profile"} size={26} />
              <LinkText isSelected={activePath === "profile"}>
                Mon espace
              </LinkText>
            </LinkContainer>
          </Link>
          {currentUser ? (
            <Link to={"/auth"}>
              <LinkContainer>
                <LogOutIcon size={26} />
                <LinkText onClick={signOutAll}>Me déconnecter</LinkText>
              </LinkContainer>
            </Link>
          ) : (
            <Link to={"/auth"}>
              <LinkContainer>
                <LogInIcon size={26} />
                <LinkText>Me connecter</LinkText>
              </LinkContainer>
            </Link>
          )}
        </NavSection>
      </NavWrapper>

      <Outlet />
    </>
  );
};
const NavWrapper = styled(HStack)`
  background-color: ${ColorsSystem.powderBlueOne};
  justify-content: space-between;
  padding: 20px;
  align-items: flex-end;
  box-shadow: 0px 0px 10px ${ColorsSystem.black};
`;
const NavSection = styled(HStack)`
  gap: 30px;
  align-items: flex-end;
`;

const LinkContainer = styled(VStack)`
  gap: 6px;
  align-items: center;
  &:hover {
    cursor: pointer;
  }
`;
const LinkText = styled(RegularText)<{
  isSelected?: Boolean;
  onClick?: () => void;
}>`
  font-family: ${({ fontFamily }) => fontFamily ?? "Montserrat, sans-serif"};
  padding-top: 6;
  font-size: 1.2rem;

  color: ${(props) =>
    props.isSelected ? ColorsSystem.blue : ColorsSystem.white};
  &:hover {
    cursor: pointer;
  }
`;
const commonLinkIconStyle = css<{ isSelected?: Boolean }>`
  size: 26px;
  color: ${(props) =>
    props.isSelected ? ColorsSystem.blue : ColorsSystem.white};
  &:hover {
    cursor: pointer;
  }
`;
const LogInIcon = styled(IoLogIn)`
  ${commonLinkIconStyle};
`;
const UserIcon = styled(IoPerson)`
  ${commonLinkIconStyle};
`;
const HomeIcon = styled(IoHome)`
  ${commonLinkIconStyle};
`;
const PhotoGalleryIcon = styled(IoImages)`
  ${commonLinkIconStyle};
`;
const ContactIcon = styled(IoMail)`
  ${commonLinkIconStyle};
`;
const MembersIcon = styled(IoPeopleSharp)`
  ${commonLinkIconStyle};
`;
const AdminIcon = styled(IoBuild)`
  ${commonLinkIconStyle};
`;
const LogOutIcon = styled(IoLogOut)`
  ${commonLinkIconStyle};
`;
const BulletinIcon = styled(IoDownloadSharp)`
  ${commonLinkIconStyle};
`;
const PresentationIcon = styled(IoBookSharp)`
  ${commonLinkIconStyle};
`;

import { OutlineButton, TextButton } from "components/ui/buttons";
import { PageWrapper } from "components/ui/containers/page";
import { H2, H3, H4 } from "components/ui/headings";
import { HStack, VStack } from "components/ui/containers/stacks";
import { Panel } from "components/ui/cards";
import { ColorsSystem } from "components/ui/colors";
import { RegularText } from "components/ui/texts";
import { IoDownload } from "react-icons/io5";
import { FirebaseBulletinGateway } from "utils/firebase/firebase_bulletin";
import { useEffect, useState } from "react";
import { Bulletin } from "types/bulletin";

export const BulletinPage = () => {
  const [bulletins, setBulletins] = useState<Bulletin[]>([]);

  const bulletinsSortedByDate = bulletins.sort((a, b) => {
    return b.bulletinNumber - a.bulletinNumber;
  });

  const bookCoverImage = require("assets/livreMockUp.jpg");
  useEffect(() => {
    async function fetchBulletins() {
      const bulletinGateway = new FirebaseBulletinGateway();
      const fetchedBulletins = await bulletinGateway.getBulletins();
      setBulletins(fetchedBulletins);
    }
    fetchBulletins();
  }, []);

  const handleRedirection = (url: string) => {
    window.open(url);
  };

  const downLoadBulletin = async (fileUrl: string, fileName: string) => {
    try {
      const response = await fetch(fileUrl, { mode: "cors" });

      if (!response.ok) {
        throw new Error(`Erreur HTTP ${response.status}`);
      }

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);

      const a = document.createElement("a");
      a.href = url;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);

      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Erreur lors du téléchargement :", error);
      alert(
        "Impossible de télécharger le fichier. Vérifie les permissions CORS."
      );
    }
  };

  return (
    <PageWrapper>
      <VStack alignItems="center" gap={60}>
        <H4>Bulletins</H4>
        <HStack
          wrap="wrap"
          gap={60}
          alignItems="center"
          justifyContent="center"
          fullWidth
        >
          {bulletinsSortedByDate.map((bulletin, index) => {
            return (
              <Panel key={index}>
                <VStack alignItems="center" gap={20}>
                  <div
                    style={{
                      position: "relative",
                      width: 210,
                      height: 297,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <img width={210} src={bookCoverImage} alt="header"></img>
                    <div
                      style={{
                        position: "absolute",
                        top: 297 / 3,
                        right: 210 / 2.45,
                      }}
                    >
                      <H2 color={ColorsSystem.gold} textAlign="center">
                        {bulletin.bulletinNumber}
                      </H2>
                    </div>
                  </div>
                  <OutlineButton
                    title="voir le bulletin"
                    buttonSize={"small"}
                    onClick={() => handleRedirection(bulletin.viewerLink)}
                  />
                  <TextButton
                    leftIcon={IoDownload}
                    title="télécharger"
                    buttonSize={"small"}
                    onClick={() =>
                      downLoadBulletin(
                        bulletin.storageLink,
                        `Bulletin-${bulletin.bulletinNumber}.pdf`
                      )
                    }
                  />
                </VStack>
              </Panel>
            );
          })}
        </HStack>
      </VStack>
    </PageWrapper>
  );
};

import { GalleryCard } from "components/reunions_gallery/gallery_card";
import { useContext, useEffect, useState } from "react";
import "firebase/storage";

import { Reunion } from "types/reunions.types";
import { FirebaseReunionGateway } from "utils/firebase/firebase.reunions";
import { H4 } from "components/ui/headings";
import { PageWrapper } from "components/ui/containers/page";
import { ModalContext } from "components/ui/modal";
import { ImagesCarousel } from "components/reunions_gallery/gallery_images_carousel";
import { HStack } from "components/ui/containers/stacks";
import styled from "styled-components";
import { useUserContext } from "contexts/user_context";

export const Reunions = () => {
  const [reunions, setReunions] = useState<Reunion[]>([]);
  useEffect(() => {
    async function fetchReunions() {
      const reunionGateway = new FirebaseReunionGateway();
      // const reunionsBis2 = await reunionGateway.getReunions();
      const fetchedReunions = await reunionGateway.getReunions();

      setReunions(fetchedReunions);
    }
    fetchReunions();
  }, []);

  const { currentUser } = useUserContext();

  const admins = [
    "celyne.giaume@ouilive.info",
    "celine.giaume@gmail.com",
    "celyne@giaume.com",
  ];
  const isCurrentUserAdmin = admins.includes(currentUser?.email || "");

  return (
    <PageWrapper>
      <H4>Revivez en photos les réunions de la promotion</H4>
      <Wrapper wrap="wrap">
        {reunions
          .filter((x) => isCurrentUserAdmin || !x.title.includes("TESTREUNION"))
          .map((reunion, index) => {
            return (
              <Gallery key={index} reunion={reunion} index={index}></Gallery>
            );
          })}
      </Wrapper>
    </PageWrapper>
  );
};

type GalleryProps = { reunion: Reunion; index: number };
const Gallery = ({ reunion, index }: GalleryProps) => {
  const { handleModal } = useContext(ModalContext);

  if (reunion.galleryImages.length <= 0) return;

  const handleSeeReunionCarouselModal = (reunion: Reunion): void => {
    reunion &&
      handleModal(
        <ImagesCarousel
          title={reunion.title}
          images={reunion.galleryImages.map((x) => x.imageURL)}
        />
      );
  };

  return (
    <>
      <GalleryCard
        backgroundImage={reunion.galleryImages[0]?.imageURL}
        text={reunion.title}
        date={reunion.date}
        imagesCount={`${reunion.galleryImages.length} photos`}
        onPress={() => handleSeeReunionCarouselModal(reunion)}
      />
    </>
  );
};

const Wrapper = styled(HStack)`
  margin-top: 40px;
  justify-content: center;
  gap: 40px;
`;
